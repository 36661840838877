.adyen-checkout__threeds2__challenge-container,
.adyen-checkout__threeds2__challenge {
    background-color: transparent;
    box-sizing: border-box;
    display: block;
    position: relative;
    overflow: hidden;
    width: 100%;
}

.adyen-checkout__threeds2__challenge {

    &--01 {
        width: 250px;
        height: 400px;

        .adyen-checkout__iframe--threeDSIframe {
            width: 250px;
            height: 400px;
        }
    }

    &--02 {
        width: 390px;
        height: 400px;

        .adyen-checkout__iframe--threeDSIframe {
            width: 390px;
            height: 400px;
        }
    }

    &--03 {
        width: 500px;
        height: 600px;

        .adyen-checkout__iframe--threeDSIframe {
            width: 500px;
            height: 600px;
        }
    }

    &--04 {
        width: 600px;
        height: 400px;

        .adyen-checkout__iframe--threeDSIframe {
            width: 600px;
            height: 400px;
        }
    }

    &--05 {
        width: 100%;
        height: 100%;

        .adyen-checkout__iframe--threeDSIframe {
            width: 100%;
            height: 100%;
        }
    }
}

.adyen-checkout__threeds2__challenge.adyen-checkout__threeds2__challenge--05 {
    padding-top: 56.25%;
}

.adyen-checkout__iframe--threeDSIframe {
    position: absolute;
    top: 0;
    left: 0;
    border: 0;
}
