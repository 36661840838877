@import '../../../style';

.adyen-checkout__qr-loader {
    background: $color-white;
    padding: 40px;
    border: 1px solid $color-gray;
    border-radius: $border-radius-large;
    text-align: center;
}

.adyen-checkout__qr-loader--result {
    padding: 100px;
}

.adyen-checkout__qr-loader--app {
    border: 0;
    border-radius: 0;
    padding: 0;
}

.adyen-checkout__qr-loader__brand-logo {
    width: 74px;
    border-radius: 3px;
}

.adyen-checkout__qr-loader__subtitle {
    margin-top: 32px;
}

.adyen-checkout__qr-loader__subtitle--result {
    margin-bottom: 32px;
}

.adyen-checkout__qr-loader__subtitle,
.adyen-checkout__qr-loader__payment_amount {
    color: $color-black;
    font-size: $font-size-medium;
    line-height: 19px;
}

.adyen-checkout__qr-loader__icon {
    width: 88px;
    height: 88px;
}

.adyen-checkout__qr-loader__payment_amount {
    font-weight: bold;
}

.adyen-checkout__qr-loader__progress {
    height: 4px;
    background: $color-gray;
    border-radius: $border-radius-xlarge;
    margin: 32px auto 12px auto;
    width: 152px;
    padding-right: 3%;
}

.adyen-checkout__qr-loader__percentage {
    display: block;
    height: 100%;
    border-radius: $border-radius-xlarge;
    background: $color-primary;
}

.adyen-checkout__qr-loader__countdown {
    color: $color-gray-darker;
    font-size: $font-size-small;
}

.adyen-checkout__qr-loader > .adyen-checkout__spinner__wrapper {
    margin: 60px 0;
}

.adyen-checkout__qr-loader__app-link {
    margin-top: 16px;
    display: none;
}

.adyen-checkout__qr-loader__separator__label {
    position: relative;
    font-size: 13px;
    color: $color-gray-darker;
    overflow: hidden;
    text-align: center;
    z-index: 1;
    display: block;
}

.adyen-checkout__qr-loader__separator__label:before,
.adyen-checkout__qr-loader__separator__label:after {
    position: absolute;
    top: 51%;
    overflow: hidden;
    width: 50%;
    height: 1px;
    content: '\a0';
    background-color: $color-gray-light;
}

.adyen-checkout__qr-loader__separator__label:before {
    margin-left: -52%;
    text-align: right;
}

.adyen-checkout__qr-loader__separator__label:after {
    margin-left: 2%;
}

.adyen-checkout__button.adyen-checkout__button--qr-loader {
    text-decoration: none;
    margin-top: 24px;
}

.adyen-checkout__qr-loader__instructions {
    color: $color-gray-darker;
    font-size: $font-size-medium;
    line-height: 1.5;
    margin-top: 16px;
}

@media only screen and (max-device-width: 1200px) {
    .adyen-checkout__qr-loader__app-link {
        display: block;
    }
}
